<template>
    <a-card>
        <a-page-header
            title="橙子建站模板详情"
        />

        <a-form-model
            ref="form"
            :model="form"
            :rules='rules'
            v-bind='layout'
        >
            <a-form-model-item label="模版名称">{{ dataInfo.name }}</a-form-model-item>
            <a-form-model-item label="系统识别品牌">{{ dataInfo.principalName }}</a-form-model-item>
            <a-form-model-item label="系统识别车系">{{ dataInfo.carSeriesName }}</a-form-model-item>
            <a-form-model-item label="系统识别集客场景">{{ dataInfo.customerSceneDesc }}</a-form-model-item>


            <a-form-model-item label="模板使用范围" prop='templateScope'>
                <a-radio-group v-model="form.templateScope" @change="onChangeScope">
                    <a-radio :value="1">车系</a-radio>
                    <a-radio :value="2">品牌</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="品牌车系" prop="principalId" style="margin-bottom: 0">
                <a-select
                    v-model="form.principalId"
                    placeholder="请选择品牌"
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                    @change="onChangePrincipal"
                >
                    <a-select-option
                        v-for="item in principalList"
                        :key="item.id"
                    >
                        {{ item.principal_name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false" prop="carSeriesId" v-if="form.templateScope != 2">
                <a-select
                    v-model="form.carSeriesId"
                    placeholder="请选择车系"
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                    :disabled="!form.principalId"
                >
                    <a-select-option
                        v-for="item in seriesList"
                        :key="item.id"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="集客场景" prop='customerScene' style="margin-top: 24px;">
                <a-radio-group v-model="form.customerScene">
                    <a-radio
                        v-for="item in customerSceneList"
                        :key="item.code"
                        :value="item.code"
                    >
                        {{ item.desc }}
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>

            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
import wordLimitInput from '@/components/wordLimitInput'

export default {
    components: {
        wordLimitInput
    },
    data() {
        let checkCarSeriesId = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请选择车系'))
            } else {
                callback();
            }
        }
        
        return {
            id: this.$route.query.id,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            dataInfo: {},
            form: {
                templateScope: 1,
                principalId: undefined,
                carSeriesId: undefined,
                customerScene: 1,
            },
            rules: {
                templateScope: [
                    { required: true, message: '请选择标题适用范围', trigger: 'change' }
                ],
                principalId: [
                    { required: true, message: '请选择品牌', trigger: 'change' }
                ],
                carSeriesId: [
                    { validator: checkCarSeriesId, trigger: 'change' }
                ],
                customerScene: [
                    { required: true, message: '请选择集客场景', trigger: 'change' }
                ],
            },
            isSubmit: false,
            principalList: [],
            seriesList: [],
            customerSceneList: [],
        }
    },
    created() {
        this.getPrincipalList()
        this.getCustomerScene()
        this.getLandingPageTemplateDetail()
    },
    methods: {
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangeScope(e) {
            this.form.carSeriesId = undefined
            if(e.target.value == 1) {
                this.getPrincipalSeriesList(this.form.principalId)
            }
        },
        onChangePrincipal(id) {
            this.form.carSeriesId = undefined
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: [id],
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/websiteTemplateManage'
                    })
                }
            })
        },
        handleSubmit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    if(this.form.templateScope == 2) {
                        this.form.carSeriesId = undefined
                    }
                    this.$api.core.materialManage.submitLandingPageTemplateDetail( this.form ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success('提交成功')
                            this.$router.push({
                                path: '/websiteTemplateManage'
                            })
                        } else {
                            this.$message.error(`提交失败，${res.message}`)
                        }
                    })
                }
            })
        },
        getLandingPageTemplateDetail() {
            this.$api.core.materialManage.getLandingPageTemplateDetail( this.id ).then(res => {
                if(res.code == 200) {
                    let _data = res.data
                    this.dataInfo = _data
                    this.form = {
                        id: this.id,
                        templateScope: _data.templateScope || 1,
                        principalId: _data.principalId,
                        carSeriesId: _data.carSeriesId,
                        customerScene: _data.customerScene || 1,
                    }
                    if(_data.templateScope == 1) {
                        this.getPrincipalSeriesList(_data.principalId)
                    }
                } else {
                    console.error(`获取详情失败，${res}`)
                }
            })
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>